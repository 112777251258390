@charset "UTF-8";

@import "@startinblox/hubl-styling-framework/dist/index.css";

@font-face {
  font-family: 'ConverseFontAwesomeBrands';
  font-style: normal;
  font-weight: normal;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'ConverseFontAwesomeRegular';
  font-style: normal;
  font-weight: 400;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.svg#fontawesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ConverseFontAwesomeSolid';
  font-style: normal;
  font-weight: 900;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}


solid-display>div {
  display: contents !important;
}

@import 'header';
@import 'left-nav';
@import 'content';
@import 'about';
@import 'members';
@import 'profile';
@import 'job-offers';
@import 'chat';
@import 'forms';

nav#main__menu {
  @media (min-width: 768.01px) {
    width: 250px;
  }
}

main#content {
  @media (min-width: 768.01px) {
    width: calc(100% - 250px);
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.admin-name-ellipsis {
    display: inline-block;
    width: 75%;
  }
}

hubl-create + hubl-create {
  display: none; /* Duplicate widget generation? */
}

hubl-create-contact + hubl-create-contact {
  display: none; /* Duplicate widget generation? */
}

hubl-create, hubl-create-contact {
  solid-link {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.swal2-container {
  z-index: 50000!important;
}

/* Fix on Join button in admin (circles + projects) */
/* Styles on buttons and .children-link don't work because this input is inside too many elements. */
/* And no I can't add that stupid icon because it'a an input. */
.join-button {

  input {
    padding: 9px 20px;
    border-radius: 16.5px;
    height: 33px;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--color-secondary);
    color: white;
    border: 1px solid var(--color-secondary);

    &:hover {
      background: white;
      color: var(--color-secondary);
    }
  }
}