nav#main__menu {
  height: calc(100vh - 72px);

  @media (max-width: 768px) {
    min-height: calc(100vh - 50px);
  }

  .unread {
    font-weight: bolder;
  }

  solid-route.menu,
  solid-link.menu,
  div.menu {
  
    .icon {
      padding: 8px;
      border-radius: 50%;
    }
    &:hover .icon,
    &[active] .icon {
      color: white;
      background: var(--color-heading);
    }
    &[active]+.divider {
      visibility: hidden;
    }
  }

  .sub-menu {
    padding-bottom: 10px;
  }

  /* Styles for the small icon */
  .sub-menu-icon {
    margin-left: 3px;
    margin-right: 3px;
    opacity: 0.8;
    width: 12px;
    >div {
      line-height: 19px;
    }
  }

  .sub-menu-name {
    margin-right: 15px;
    vertical-align: middle;
  }

  .menu-chevron {
    float: right;
    margin-top: 8px;
  }


  /* Ellipsis */
  .ellipsis {

    hubl-menu-fix-url-circle,
    hubl-menu-fix-url-project {
      display: block;
    }

    .ellipsis-content,
    .ellipsis-content>div {
      display: block;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  .badge {
    float: right;
  }

  .divider {
    height: 1px;
    background-color: #D7DBE0;
    opacity: 0.2;
  }

  .create {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    width: 87%;
  }

  &.jsLeftMenu {
    display: inline-block;

    @media(max-width: 768px) {
      display: none;
    }
  }

  &.jsLeftMenu[open] {
    display: none;

    @media (max-width: 768px) {
      display: block;
      bottom: 0;
      box-shadow: 0 2px 8px 0 rgba(65, 69, 73, 0.5);
      width: 100%;
      position: fixed;
      right: 0;
      top: 50px;
      z-index: 3000;
    }
  }
}

