header {
  height: 72px;
  position: fixed;
  z-index: 10000;

  >div {
    display: flex;

    >div {
      display: flex;
      margin-left: auto;
    }
  }

  solid-link {
    align-self: center;

    img.logo {
      max-width: 200px;
      max-height: 34px;
      vertical-align: middle;
    }
  }

  .tag {
    align-self: flex-start;
    padding: 4px 15px;
    margin-top: 16px;
    margin-left: 60px;
  }

  solid-notifications {
    color: var(--color-heading);
    align-self: center;

    @media(min-width: 768.01px) {
      margin-right: 23px;
    }

    .solid-notifications__container {
      position: relative;

      &[open] summary {
        background-color: white;
        color: var(--color-heading);
      }

      .solid-notifications__button {
        margin-top: -9px;
        font-size: 24px;

        &::before {
          margin-left: -9px;
        }

        &:hover, &:focus {
          background: white;
        }

        img {
          display: none;
        }

        .solid-notifications__counter {
          left: 12px;
          position: absolute;
          top: 1px;

          span {
            background-color: var(--color-primary);
            color: white;
            font-size: 11px;
          }
        }
      }

      .solid-notifications__triangle-back {
        display: none;
      }

      .solid-notifications__triangle-shadow {
        display: none;

        @media(min-width: 768.01px) {
          background: white;
          display: block;
          position: absolute;
          left: auto;
          right: -1.9em;
          top: 4em;
          transform: initial;
          width: 0;
          z-index: 4;

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -5em;
            top: 0px;
            left: 50%;
            border: 1em solid black;
            border-color: #fff #fff transparent transparent;
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: 6px -6px 10px -3px rgba(92, 97, 104, 0.14);
          }
        }
      }

      .solid-notifications__list {
        border: none;
        border-radius: 0;
        box-shadow: 0 0 7px 0 rgba(92, 97, 104, 0.24);
        max-height: calc(100vh - 50px);
        position: fixed;
        left: 0;
        top: 2.5em;
        width: 100vw;

        @media(min-width: 768.01px) {
          max-height: calc(100vh - 93px);
          position: absolute;
          left: auto;
          right: -2.3em;
          top: 2.95em;
          width: 347px;
        }

        /* Custom scrollbar */
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: thin;
        scrollbar-color: #C9C8C8 #F1F1F1;

        &::-webkit-scrollbar-track {
          background: #F1F1F1;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #C9C8C8;
          border-radius: 8px;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        /* End of custom scrollbar */

        .solid-notifications__item {
          border-bottom: none;

          &:hover {
            background-color: transparent;

            & solid-notification__title,
            & solid-notification__summary {
              color: var(--color-secondary);
            }
          }

          .solid-notification {
            padding: 16px 0 5px;

            &[data-read] {
              opacity: 0.5;
            }

            .solid-notification__avatar {
              align-items: center;
              background: #f6f6f6;
              display: flex;
              height: 35px;
              justify-content: center;
              margin-left: 16px;
              margin-right: 14px;
              overflow: hidden;
              position: relative;
              vertical-align: middle;
              width: 35px;

              img {
                background-color: #f6f6f6;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;
              }

              object {
                height: 45%;
                width: 45%;
              }
            }

            .solid-notification__content {
              font-family: Open Sans, sans-serif;

              .solid-notification__title {
                color: var(--color-heading);
                font-size: 13px;
                font-weight: 600;

                .solid-notification__title__side {
                  font-weight: normal;

                  >span:first-child {
                    margin-right: 4px;
                  }
                }

                .solid-notification__title__icon {
                  display: inline-block;
                  font-family: simple-line-icons;
                }
              }

              .solid-notification__summary {
                color: #636363;
                font-size: 12px;
                line-height: 17px;
                margin-top: 4px;

                @media(min-width: 768.01px) {
                  max-width: 256px;
                }
              }
            }

            .solid-notification__date {
              display: none;
            }

            .solid-notification__read {
              display: none;
            }
          }
        }
      }
    }
  }

  details {

    &[open] {
      summary {
        background-color: var(--color-heading);
        color: #fff;
        .text-color-heading {
          color: #fff;
        }
        .labelled-avatar>div {
          line-height: normal;
        }
      }
    }
    summary {
      display: block; /* To remove the disclosure triangle on Firefox v.>=69 */
      cursor: pointer;
      padding-right: 15px;
      padding-left: 15px;
      height: 72px;

      /* To remove the disclosure triangle on Chrome (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#Customizing_the_disclosure_widget) */
      &::marker,
      &::-webkit-details-marker {
        display: none;
      }
      /* End */
    }
  }

  .panel {
    height: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    /* Quick fix for mobile version */
    width: 100%;
    top: 72px;

    >nav {
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.16);
      height: auto;
      width: 100%;

      solid-route,
      solid-link,
      button {
        display: block;
      }

      li,
      button {
        width: 100%;
        text-align: left;
      }
    }
  }





  @media (max-width: 768px) {
    height: 50px;

    .icon-menu {
      font-size: 24px;
    }

    .tag {
      align-self: center;
      margin: 0;
      margin-left: 15px;
    }
    
    details.user-menu {

      .user-firstname {
        display: none;
      }

      summary {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        padding: 0;

        >.labelled-avatar .avatar {
          height: 32px;
          width: 32px;
        }

        .sm-arrow-down {
          width: 16px;
          height: 16px;
          background: white;
          border-radius: 50%;
          text-align: center;
          position: absolute;
          top: 26px;
          right: -1px;
          box-shadow: 0 0 4px 0 rgba(46, 63, 87, 0.18);

          .icon::before {
            font-size: 10px;
          }
        }
      }

      &[open] {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        height: 72px;

        summary {
          display: flex;
          height: 72px;
        }

        .labelled-avatar {
          display: none;
        }

        .user-firstname {
          display: flex;
          align-items: center;
          padding-right: 15px;
          padding-left: 15px;
          width: 100%;

          [name="segment3"] {
            margin-left: auto;

            .icon::before {
              font-size: 20px;
            }
          }
        }

        .panel {
          top: 72px;
          height: calc(100vh - 72px);
          background: white;

          >nav {
            box-shadow: none;

            >ul>li:last-child {
              border-bottom: 1px solid #d6cece;
            }
          }
        }
      }
    }
  }
  .solid-notifications__item:hover * {
    color: var(--color-secondary)!important;
  }
  .solid-notification__content {
    max-width: calc(100% - 65px);
  }
  .solid-notification__summary {
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
  .solid-notification__title {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
}
