main {
  height: calc(100vh - 72px);

  @media (max-width: 768px) {
    height: calc(100vh - 50px);
  }
}

/* Styles of the right-hand menu + pages with that menu */
.with-sidebar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*margin-top: 50px;*/
  overflow: hidden;

  .views-container {
    flex: 1 0 0;
    /*-webkit-backface-visibility: hidden;*/

    &.sidebar-is-closed {

      @media(min-width: 768.01px) {
        margin-left: -152px;
        transform: translate(152px);
      }
    }
  }

  >div {
    transition: all 0.5s;
  }

  .sidebar {
    background: #F1F1F1;
    height: calc(100vh - 72px);
    transition: all 0.5s;
    width: 217px;

    @media (max-width: 768px) {
      height: calc(100vh - 50px);
      width: 65px;
    }

    ul {

      li {
        border-bottom: 1px solid #D6CECE;

        &>a {
          vertical-align: super;
        }
      }
      &>li:first-child>a {
        vertical-align: middle;
      }

      >solid-route {

        &[active]>li {
          background-color: var(--color-heading);
          color: white;
        }
        &:hover:not([active])>li {
          background: #E4E4E4;
        }
      }
    }

    /* Styles use with JS to open/close the sidebar */
    &.jsRightMenu {
      display: block;
      solid-link[active] {
        color: white;
        background: var(--color-heading);
      }
      solid-link:not([active]):hover {
        background: #e4e4e4;
      }
    }

    &.jsRightMenu:not([open]) {
      @media (min-width: 768.01px) {
        transform: translate(152px);
      }

      /* Quick fix. When you got time, use animation on the span instead */
      @media (max-width: 768px) {
        ul>li:first-child>span,
        ul>li:first-child>a {
          visibility: hidden;
        }
      }
    }

    &.jsRightMenu[open] {
      display: block;
      bottom: 0;
      right: 0;

      @media(max-width: 768px) {
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.12);
        min-width: 60%;
        position: fixed;
        top: 50px;
        z-index: 3000;
      }
    }
  }
}

/* Add scrollbar to the left and to the content */
.scrollbar-nav,
.scrollbar-content {
  overflow-y: auto;
  height: calc(100vh - 72px); /* 72px == header's height */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media (max-width: 768px) {
    height: calc(100vh - 50px);
  }
}

/* Custom scrollbar of the left-menu*/
/* Works on Firefox*/
.scrollbar-nav {
  scrollbar-width: thin;
  scrollbar-color:#D6CECE var(--color-heading);
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-nav {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-heading);          /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color:#D6CECE;                /* color of the scroll thumb */
    border-radius: 6px;                        /* roundness of the scroll thumb */
    border: 3px solid var(--color-heading);    /* creates padding around scroll thumb */
  }
}




/* Custom scrollbar of the content */
/* Works on Firefox */
.scrollbar-content {
  scrollbar-width: thin;
  scrollbar-color:#D6CECE white;            /* scroll thumb and track */
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-content {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: white;                        /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color:#D6CECE;                 /* color of the scroll thumb */
    border-radius: 6px;                         /* roundness of the scroll thumb */
    border: 3px solid white;                  /* creates padding around scroll thumb */
  }
}


solid-display-value-markdown {
  
  p {
    margin: 0;
  }
}
